<template>
	<div class="page_content">
		<div class="page_detail">
			<a-divider orientation="left">订单信息</a-divider>
			<a-descriptions :column="2">
			    <a-descriptions-item label="订单编号">
			        {{detail.order_id }}
			    </a-descriptions-item>
				<a-descriptions-item label="订单类型">
				   {{ detail.flag=='h'?'酒店代订':'出差行程' }}
				</a-descriptions-item>
				<a-descriptions-item label="订单状态">
				   {{ detail.status_name }}
				</a-descriptions-item>
			    <a-descriptions-item label="酒店ID">
			        {{ detail.hotel_id }}
			    </a-descriptions-item>
			    <a-descriptions-item label="失败原因" v-if="detail.status == 22">
			        {{ detail.reason || '无' }}
			    </a-descriptions-item>
			    <a-descriptions-item label="酒店名称">
			       {{ detail.hotel_name||'--' }}
			    </a-descriptions-item>
			   <a-descriptions-item label="酒店地址">
			       {{ detail.address||'--' }}
			   </a-descriptions-item>
			   <a-descriptions-item label="联系电话">
			      {{ detail.contact_phone||'--' }}
			   </a-descriptions-item>
			</a-descriptions>
			<a-divider orientation="left">订房信息</a-divider>
			<a-descriptions :column="2">
			    <a-descriptions-item label="入住时间">
			        {{ detail.check_in_date ||'--'}}
			    </a-descriptions-item>
			    <a-descriptions-item label="离店时间">
			        {{ detail.check_out_date||'--' }}
			    </a-descriptions-item>
			    <a-descriptions-item label="入住人">
			        {{ detail.person_name||'--' }}
			    </a-descriptions-item>
				<a-descriptions-item label="下单时间">
				    {{ detail.create_time }}
				</a-descriptions-item>
				<a-descriptions-item label="订单总额" v-if='detail.order_price'>
				    {{ detail.order_price }}元
				</a-descriptions-item>
			</a-descriptions>
				<a-divider orientation="left">入住信息</a-divider>
					<div class="li_item">
						<div class="arpproval_title">
							<span class="department_appr">入住时间</span>
							<span class="department_appr">房型</span>
							<span class="department_appr">数量</span>
							<span class="department_appr">价格/晚</span>
						</div>
						<div class="arpproval_title arpproval_title1" v-for="room in detail.check_in">
							<span class="department_appr">{{room.date}}</span>
							<span class="department_appr">{{room.room_name ||'--'}}</span>
							<span class="department_appr">{{room.room_num}}</span>
							<span class="department_appr"> {{room.price.toLocaleString()}}元</span>
						</div>
					</div>
					
			<a-divider orientation="left" v-if='detail.trip.username'>关联行程信息</a-divider>
			<div class="li_item" v-if='detail.trip.username'>
				<div class="arpproval_title">
					<span>员工姓名</span>
					<span class="department_appr">部门</span>
					<span>状态</span>
					<span class="department_appr">出发时间</span>
					<span  class="department_appr">出发地</span>
					<span class="department_appr">到达时间</span>
					<span class="department_appr">到达地</span>
					<span >操作</span>
				</div>
				<div class="arpproval_title arpproval_title1" >
					<span>{{detail.trip.username}}</span>
					<span class="department_appr">{{detail.trip.department_name}}</span>
					<span>{{detail.trip.status?'已出行':'未出行'}}</span>
					<span class="department_appr"> {{detail.trip.start_time}}</span>
					<span  class="department_appr">{{detail.trip.start_address}}</span>
					<span class="department_appr">{{detail.trip.end_time}}</span>
					<span  class="department_appr">{{detail.trip.end_address}}</span>
					<span class="go_see" @click="go_trip">查看</span>
				</div>
			</div>
			<div class="box_form box_form_btns">
				<a-button type="defalut" @click="$router.back()">返回</a-button>
			</div>
			
			</div>
			</div>
	</div>
</template>

<script>
	import LookImages from "@/components/LookImages";
	import {
		getOrderInfo
	} from '@/api/evection.js'
	import Avatar from "@/components/small/Avatar";
	export default {
		components: {
			LookImages,
			Avatar
		},
		data() {
			return {
				detail: {
					trip: {},
					check_in:[]
				}
			}
		},
		created() {
			this.get_info()
		},
		methods: {
			go_trip(){
				this.$router.push({
					path:'trip_details',
					query:{
						id:this.detail.trip.approval_id,
						plan_id:this.detail.trip.plan_id,
						type:this.detail.trip.type,
					}
				})
			},
		
			get_info() {
				getOrderInfo({
					data: {
						order_id: this.$route.query.order_id,
					}

				}).then(res => {
					this.detail = res.data.data
				})
			},
		}
	}
</script>

<style lang="less" scoped>
	.time_line {
		max-width: 600px;
	}

	.avatar_ul {
		display: flex;
		flex-wrap: wrap;

		.avatar {
			margin: 16px 10px 0;
			width: 80px;
		}
	}

	.page_detail {
		margin: 0;
		margin-left: 5%;

	}

	.li_item {
		width: 100%;
		.title_item {
			width: 80px;
			text-align: left;
			display: inline-block;
			color: #000;
		}
		margin-bottom: 30px;
	}
	.arpproval_title {
		padding: 15px 0;
		
		text-align: center;
		background: rgba(245, 245, 245, 1);
		font-size: 14px;
		span{
			width:6%;
			display: inline-block;
		}
		.department_appr {
			width: 15%;
		}
	}
	.arpproval_title1 {
		height: auto;
		background: none;
		border: 1px solid rgba(245, 245, 245, 1);
	}
	.go_see {
		color: #1890ff;
	}
</style>
